@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: inherit;
}


::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.particles-js{
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

.content{
    position: absolute;
    top: 25px;
    padding-bottom: 77.5px;
    left: 10%;
    right: 10%;
}

@media (max-width: 768px){
    .content{
        left: 5%;
        right: 5%;
    }
}

.title{
    color: white;
    text-align: center;
    font-size: 2.75rem !important;
}